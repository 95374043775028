<template>
  <main>
    <a
      v-if="timeLeft > 0"
      class="button w-button cpnapply"
      target="blank"
      style="
        margin: 0 5px 0 5px;
        background: maroon;
        width: 200px;
        height: 60px;
        text-align: center;
        font-size: 20px;
      "
      disabled
    >
      {{ siteName }}<br />
      {{ convertTime(timeLeft) }}
    </a>
    <a
      v-else
      class="button w-button cpnapply"
      :href="url"
      target="_blank"
      style="
        margin: 0 5px 0 5px;
        min-width: 200px;
        height: 60px;
        text-align: center;
        font-size: 25px;
        padding: 20px 20px;
      "
      @mousedown="checkVote()"
    >
      {{ convertTime(timeLeft) }}
    </a>
  </main>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    id: Number,
    siteName: String,
    siteUrl: String,
    username: String,
  },
  data: () => ({
    timeLeft: '',
    url: '',
    incentive: Math.random().toString(36).slice(2),
  }),
  async created() {
    this.checkVotes();
  },
  mounted() {
    this.url = this.siteUrl.replace('{incentive}', this.incentive);
  },
  methods: {
    async createVote() {
      await axios.post(
        `${process.env.VUE_APP_MAIN_API_URL}/api/vote/create`,
        {
          id: this.id,
          uid: this.incentive,
          username: this.username,
        }
      );
    },
    checkVote() {
      this.createVote();
      if (this.timeLeft < 1) {
        this.clickCheck = setInterval(() => {
          if (this.timeLeft > 0) {
            clearInterval(this.clickCheck);
          }
          this.checkVotes(this.id);
        }, 5000);
      }
    },
    async checkVotes() {
      await axios
        .get(
          `${process.env.VUE_APP_MAIN_API_URL}/api/votes/check/${this.id}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            this.timeLeft = response.data[0].seconds_left;
            if (this.timeLeft > 0) {
              this.countdownTimer();
            }
          }
        });
    },
    countdownTimer() {
      this.countdown = setInterval(() => {
        this.timeLeft--;
      }, 1000);

      // this.username = seconds;
    },
    convertTime(sec) {
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = '0' + hours;
      }
      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      if (sec < 1) {
        return this.siteName;
      }
      return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
    },
  },
};
</script>
