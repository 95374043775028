/* eslint-disable */
<template>
  <div>
    <div class="header-section sub-page">
      <div class="header-text">
        <h1 class="page-heading">
          SIMPLICITY<br /><span class="page-heading large">VOTE</span>
        </h1>
      </div>
    </div>
    <div class="page-section">
      <div class="container">
        <div class="store-layout-grid">
          <div>
            <div class="content-box">
              <div class="maintitle-section-block">
                <div class="medium-title">
                  <div class="text-loading">
                    {{
                      voteStage === voteStages.VOTE_ON_SITES
                        ? `Voting as ${username}`
                        : 'Vote for Simplicity'
                    }}
                  </div>
                </div>
              </div>
              <p>Help the community by voting on the sites below!</p>
              <p>You will be rewarded per vote!</p>

              <div v-if="voteStage === voteStages.ENTER_USERNAME">
                <div class="discord-code-row">
                  <input
                    v-model="username"
                    type="text"
                    maxlength="256"
                    placeholder="Enter username"
                    class="input-field no-margin w-input cpncode"
                    required="true"
                  />
                  <a
                    class="button w-button cpnapply"
                    @click="continueWithVote"
                    >Continue</a
                  >
                </div>
              </div>
              <div
                v-else-if="voteStage === voteStages.VOTE_ON_SITES"
                style="padding-top: 20px"
              >
                <div class="vote-row">
                  <span
                    v-for="votePage in votedRunelocus
                      ? otherVoteSites
                      : runelocusVote"
                    :key="votePage.name"
                    style="margin: 0 5px"
                  >
                    <VoteTimer
                      :id="votePage.id"
                      :site-name="votePage.name"
                      :site-url="votePage.url"
                      :username="username"
                    />
                  </span>
                </div>
                <div style="margin-top: 30px">
                  <a
                    class="button w-button faded cpnapply"
                    @click="resetVoting"
                    >Change Username</a
                  >
                </div>
              </div>
            </div>
          </div>
          <top-voters />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopVoters from '@/components/Vote/TopVoters.vue';
import VoteStage from '@/enums/VoteStage';
import VoteTimer from '../components/Vote/VoteTimer.vue';
import axios from 'axios';

export default {
  name: 'VotePage',
  components: { TopVoters, VoteTimer },
  data: () => ({
    username: '',
    votedRunelocus: false,
    voteStage: VoteStage.ENTER_USERNAME,
    runelocusVote: [
      {
        id: 1,
        name: 'RUNELOCUS',
        url: 'https://www.runelocus.com/top-rsps-list/simplicityps/vote?callback={incentive}',
      },
    ],
    otherVoteSites: [
      {
        id: 1,
        name: 'RUNELOCUS',
        url: 'https://www.runelocus.com/top-rsps-list/simplicityps/vote?callback={incentive}',
      },
      {
        id: 2,
        name: 'Top100Arena',
        url: 'https://www.top100arena.com/listing/94246/vote?incentive={incentive}',
      },
      {
        id: 3,
        name: 'RSPS-LIST',
        url: 'https://www.rsps-list.com/index.php?a=in&u=simplicityps&id={incentive}',
      },
      {
        id: 4,
        name: 'TopG',
        url: 'https://topg.org/runescape-private-servers/server-616138-{incentive}',
      },
      {
        id: 5,
        name: 'Moparscape',
        url: 'https://www.moparscape.org/rsps-list/server/simplicity-rsps/?name={incentive}#vote-block',
      },
      {
        id: 6,
        name: 'Runelist',
        url: 'https://runelist.io/toplist/server/72/vote/{incentive}',
      },
    ],
  }),
  computed: {
    voteStages() {
      return VoteStage;
    },
  },
  async created() {
    this.checkRunelocus();
  },
  methods: {
    continueWithVote() {
      if (this.username.length < 1) {
        return;
      }
      this.voteStage = this.voteStages.VOTE_ON_SITES;
    },
    resetVoting() {
      this.voteStage = this.voteStages.ENTER_USERNAME;
      this.username = '';
    },
    async checkRunelocus() {
      this.clickCheck = setInterval(async () => {
        await axios
          .get(
            `${process.env.VUE_APP_MAIN_API_URL}/api/votes/check/1#check`
          )
          .then((response) => {
            if (response.data.length > 0) {
              let timeLeft = response.data[0].seconds_left;
              this.votedRunelocus =
                response.data[0].callback_date !== null &&
                timeLeft != null &&
                timeLeft >= 0;
            }
          });
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.discord-code-row {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  grid-column-gap: 0;
}

.content-box {
  padding-bottom: 40px;
}
</style>
