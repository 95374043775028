const actions = {
  addCartItem: {
    handler({ commit, state }, payload) {
      let existingCart = state.cartItems;
      let existingCartItem = existingCart.find(
        (product) => payload.id === product.id
      );

      if (existingCartItem) {
        // existingCartItem.quantity += 10;
        existingCartItem.quantity += 1;
        commit(
          'setCartItems',
          existingCart.map((a) => {
            return { ...a };
          })
        );
        return;
      }

      payload.quantity = 1;

      existingCart.push(payload);

      commit(
        'setCartItems',
        existingCart.map((a) => {
          return { ...a };
        })
      );
    },
  },
  removeCartItem: {
    handler({ commit, state }, productId) {
      let newCartItems = state.cartItems;

      for (var i = 0; i < newCartItems.length; i++) {
        if (newCartItems[i].id === productId) {
          newCartItems.splice(i, 1);
        }
      }

      commit(
        'setCartItems',
        newCartItems.map((a) => {
          return { ...a };
        })
      );
    },
  },
  clearCart: {
    handler({ commit }) {
      commit('setCartItems', []);
    },
  },
  authenticateUser: {
    handler({ commit }, isAuthenticated) {
      commit('setAdminAuthenticated', isAuthenticated);
    },
  },
};

export default actions;
