<template>
  <div>
    <h1>Store</h1>
  </div>
</template>
<script>
export default {
  created() {
    window.location.href = 'https://store.simplicityps.org';
  },
};
</script>
