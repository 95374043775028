<template>
  <div class="mx-auto lg:ml-80">
    <div class="container px-4 mx-auto">
      <section class="py-8">
        <div class="px-4 mx-auto text-white">
          <div class="pt-6 shadow rounded bg-gray-600">
            <div class="px-6 border-b">
              <div class="flex flex-wrap items-center mb-6">
                <h3 class="text-xl font-bold">Transaction History</h3>
              </div>
            </div>
            <div class="overflow-x-auto">
              <div v-if="!transactions.length">
                <p
                  style="
                    text-align: center;
                    padding: 30px;
                    font-size: 30px;
                  "
                >
                  Loading...
                </p>
              </div>
              <table v-else class="table-auto w-full">
                <thead>
                  <tr class="text-xs text-gray-50 text-left">
                    <th
                      class="flex items-center pl-6 py-4 font-medium"
                    >
                      <a class="flex items-center" href="#">
                        <span>Payer Email</span>
                      </a>
                    </th>
                    <th class="py-4 font-medium">
                      <a class="flex items-center" href="#">
                        <span>Item Number</span>
                      </a>
                    </th>
                    <th class="py-4 font-medium">
                      <a class="flex items-center" href="#">
                        <span>Transaction ID</span>
                      </a>
                    </th>
                    <th class="py-4 font-medium">
                      <a class="flex items-center" href="#">
                        <span>Payment Status</span>
                      </a>
                    </th>
                    <th class="py-4 font-medium">
                      <a class="flex items-center" href="#">
                        <span>Date</span>
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(transaction, index) in transactions"
                    :key="transaction.id"
                    :class="`text-xs ${
                      index % 2 == 0 ? 'bg-gray-700' : ''
                    }`"
                  >
                    <td
                      class="flex items-center py-5 px-6 font-medium"
                    >
                      {{ transaction.payerEmail }}
                    </td>
                    <td class="font-medium">
                      {{ transaction.productId }}
                    </td>
                    <td class="font-medium">
                      {{ transaction.txnId }}
                    </td>
                    <td>
                      <span
                        class="inline-block py-1 px-2 text-white bg-green-500 rounded-full"
                        >Completed</span
                      >
                    </td>
                    <td>{{ transaction.date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div>
          <section class="py-8">
            <div class="container px-4 mx-auto">
              <div
                class="flex flex-wrap -mx-4 items-center justify-between"
              >
                <div
                  class="w-full lg:w-auto px-4 flex items-center justify-center"
                >
                  <a
                    class="inline-flex mr-3 items-center justify-center w-8 h-8 text-xs text-gray-500 border border-gray-300 bg-white hover:bg-indigo-50 rounded"
                    @click="previousPage"
                  >
                    <svg
                      width="6"
                      height="8"
                      viewbox="0 0 6 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.53335 3.99999L4.86668 1.66666C5.13335 1.39999 5.13335 0.999992 4.86668 0.733325C4.60002 0.466659 4.20002 0.466659 3.93335 0.733325L1.13335 3.53333C0.866683 3.79999 0.866683 4.19999 1.13335 4.46666L3.93335 7.26666C4.06668 7.39999 4.20002 7.46666 4.40002 7.46666C4.60002 7.46666 4.73335 7.39999 4.86668 7.26666C5.13335 6.99999 5.13335 6.59999 4.86668 6.33333L2.53335 3.99999Z"
                        fill="#A4AFBB"
                      /></svg
                  ></a>
                  <a
                    class="inline-flex items-center justify-center w-8 h-8 text-xs text-gray-500 border border-gray-300 bg-white hover:bg-indigo-50 rounded"
                    @click="nextPage"
                  >
                    <svg
                      width="6"
                      height="8"
                      viewbox="0 0 6 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.88663 3.52667L2.05996 0.700006C1.99799 0.637521 1.92425 0.587925 1.84301 0.554079C1.76177 0.520233 1.67464 0.502808 1.58663 0.502808C1.49862 0.502808 1.41148 0.520233 1.33024 0.554079C1.249 0.587925 1.17527 0.637521 1.1133 0.700006C0.989128 0.824915 0.919434 0.993883 0.919434 1.17001C0.919434 1.34613 0.989128 1.5151 1.1133 1.64001L3.4733 4.00001L1.1133 6.36001C0.989128 6.48491 0.919434 6.65388 0.919434 6.83001C0.919434 7.00613 0.989128 7.1751 1.1133 7.30001C1.17559 7.36179 1.24947 7.41068 1.33069 7.44385C1.41192 7.47703 1.49889 7.49385 1.58663 7.49334C1.67437 7.49385 1.76134 7.47703 1.84257 7.44385C1.92379 7.41068 1.99767 7.36179 2.05996 7.30001L4.88663 4.47334C4.94911 4.41136 4.99871 4.33763 5.03256 4.25639C5.0664 4.17515 5.08383 4.08801 5.08383 4.00001C5.08383 3.912 5.0664 3.82486 5.03256 3.74362C4.99871 3.66238 4.94911 3.58865 4.88663 3.52667Z"
                        fill="#A4AFBB"
                      /></svg
                  ></a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TransactionHistoryPage',
  data: () => ({
    transactions: [],
    pageNumber: 1,
  }),
  watch: {
    pageNumber() {
      this.fetchTransactionHistory(this.pageNumber);
    },
  },
  created() {
    this.fetchTransactionHistory(1);
  },
  methods: {
    fetchTransactionHistory(pageNumber) {
      axios
        .get(
          `${process.env.VUE_APP_STORE_API_URL}/admin/get_transactions/${pageNumber}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                'access'
              )}`,
            },
          }
        )
        .then((response) => {
          this.transactions = response.data;
        });
    },
    nextPage() {
      this.pageNumber += 1;
    },
    previousPage() {
      const previousPage = this.pageNumber - 1;

      if (previousPage > 0) {
        this.pageNumber = previousPage;
      }
    },
  },
};
</script>
