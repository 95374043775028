<template>
  <div class="content-box">
    <div class="maintitle-section-block">
      <div class="medium-title">Daily deals</div>
    </div>
    <ul role="list" class="daily-deals-list">
      <li v-if="loading" class="cartisempty">
        <center>Loading...</center>
      </li>
      <li v-if="!loading && !hasPromotedProducts" class="cartisempty">
        <center>No active deals at the moment</center>
      </li>
      <li
        v-for="item in promotedProducts"
        :key="item.id"
        class="store-deal-list-item"
      >
        <div class="deal-list-item-grid">
          <img
            :src="item.productImage"
            loading="lazy"
            alt=""
            class="deals-item-img"
          />
          <div>
            <div
              class="deals-price"
              style="
                color: #ff0000;
                font-size: 18px;
                text-shadow: 0 0 6px #ff1111;
              "
            >
              <span
                class="deals-price countdown296"
                style="
                  color: #ff1111;
                  font-size: 18px;
                  text-shadow: 0 0 6px #ff2626a1;
                "
              >
                <vue-countdown-timer
                  :start-time="getCurrentDatetime"
                  :end-time="item.tempDiscountEnd"
                  :interval="1000"
                  end-text="This promotion is over."
                >
                  <template slot="countdown" slot-scope="scope">
                    <span>{{ scope.props.days }}</span
                    >:<span>{{ scope.props.hours }}</span
                    >:<span>{{ scope.props.minutes }}</span
                    >:<span>{{ scope.props.seconds }}</span>
                  </template>
                </vue-countdown-timer>
              </span>
            </div>
            <div class="store-item-name">{{ item.productName }}</div>
            <div class="compare-discount-price-flex">
              <div class="deals-price" style="color: #5fff11">
                ${{ item.tempDiscountPrice }}
              </div>
              <div class="deals-price compare">
                ${{ item.productPrice }}
              </div>
            </div>
          </div>
          <a
            class="button add-cart w-inline-block"
            @click="$emit('add-product-to-cart', item)"
          ></a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DailyDeals',
  data: () => ({
    loading: true,
    promotedProducts: [],
  }),
  computed: {
    hasPromotedProducts() {
      return this.promotedProducts.length > 0;
    },
    getCurrentDatetime() {
      return new Date();
    },
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_STORE_API_URL}/get_promoted_products`
      )
      .then((response) => {
        this.promotedProducts = response.data;
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.button.add-cart {
  background-image: url(/assets/cart-add.svg);
}
</style>
