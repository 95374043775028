<template>
  <div>
    <div class="header-section sub-page">
      <div class="header-text">
        <h1 class="page-heading">
          DOWNLOAD<br /><span class="page-heading large"
            >SIMPLICITY</span
          >
        </h1>
      </div>
    </div>
    <div class="page-section">
      <div class="container narrow">
        <div class="_3-col-grid">
          <div class="content-box no-margin">
            <div class="maintitle-section-block">
              <div class="medium-title">standard client</div>
            </div>
            <a
              href="https://cache.simplicityps.org/gamepack.jar"
              class="game-client-link-box w-inline-block"
              @click="trackConversion"
              download
              ><img
                :src="
                  require('@/assets/images/client-download-icon-client-windows.png')
                "
                loading="lazy"
                alt="Windows Client File"
                class="game-client-icon"
              />
              <div class="button large">
                <div>DOWNLOAD</div>
              </div>
            </a>
            <div class="center-text">File: .exe - 10mb</div>
          </div>
          <div
            id="w-node-b6a142d4-0ed5-c100-9f66-01c7678e17c1-a220f2ef"
            class="recommended-client"
          >
            <div class="recommended-game-tag">
              <div>RECOMMENDED</div>
            </div>
            <div class="content-box no-margin">
              <div class="maintitle-section-block">
                <div class="medium-title">launcher client</div>
              </div>
              <a
                href="/assets/Simplicity%20Launcher.jar"
                class="game-client-link-box w-inline-block"
                @click="trackConversion"
                download
                ><img
                  :src="
                    require('@/assets/images/client-download-icon-launcher.png')
                  "
                  loading="lazy"
                  alt="Simplicity Launcher Client"
                  class="game-client-icon"
                />
                <div class="button large">
                  <div>DOWNLOAD</div>
                </div>
              </a>
              <div class="center-text">File: .Jar - 10mb</div>
            </div>
          </div>
          <div class="content-box no-margin">
            <div class="maintitle-section-block">
              <div class="medium-title">cache reset tool</div>
            </div>
            <a
              href="/assets/SimplicityCacheReset.jar"
              class="game-client-link-box w-inline-block"
              @click="trackConversion"
              ><img
                :src="
                  require('@/assets/images/client-download-icon-cache-tool.png')
                "
                loading="lazy"
                alt="Cache Reset Tool Client"
                class="game-client-icon"
              />
              <div class="button large">
                <div>DOWNLOAD</div>
              </div>
            </a>
            <div class="center-text">File: .Jar - 10mb</div>
          </div>
        </div>
        <div class="download-support-section">
          <div class="maintitle-sub-label">error connecting?</div>
          <h3 class="large-title">get client support</h3>
          <div class="_2-col">
            <a
              href="https://discord.com/invite/NjbEDNg"
              target="_blank"
              class="button faded xl w-button"
              >Message the staff on Discord</a
            >
            <a
              href="https://community.simplicityps.org/index.php?/forum/42-support/"
              target="_blank"
              class="button faded xl w-button"
              >Create a ticket on the Forums</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Google tag (gtag.js) -->
<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=AW-727623269"
></script>
<script>
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', 'AW-727623269');
</script>

<script>
export default {
  name: 'PlayPage',
  methods: {
    trackConversion() {
      gtag('event', 'conversion', {
        send_to: 'AW-727623269/AtL9CITMt9wBEOXM-toC',
      });
      window._pushmonkey.manualStart();
    },
  },
};
</script>
