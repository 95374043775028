<template>
  <div>
    <div class="header-section sub-page">
      <div class="header-text">
        <h1 class="page-heading">
          SIMPLICITY<br /><span class="page-heading large"
            >HISCORES</span
          >
        </h1>
      </div>
    </div>
    <div class="container">
      <div class="col-xs-12 hs-header modes">
        <h1>
          {{
            currentUserStats
              ? currentUserStats.username
              : currentSkill
          }}
          HISCORES
        </h1>
        <p style="font-size: 15px">
          Game Mode:
          <strong>{{
            currentUserStats
              ? modes[currentUserStats.mode]
              : currentGameMode
          }}</strong
          ><span v-if="!currentUserStats"
            >, Page: <strong>{{ currentPageNumber }}</strong></span
          >
        </p>
      </div>
      <div class="body-wrapper">
        <div
          v-if="currentUserStats"
          class="col-xs-12"
          style="text-align: center; margin-bottom: 5px"
        >
          <a class="btn btn-default" @click="openOverall"
            >Back to Overall</a
          >
          <hr style="border-color: #5fff11" />
        </div>
        <div
          v-else
          class="col-xs-12"
          style="text-align: center; margin-bottom: 5px"
        >
          <a class="btn btn-default prev"
            ><i class="fa fa-angle-left fa-fw"></i
          ></a>
          <a
            v-for="gameMode in modes"
            :key="gameMode"
            :class="
              currentGameMode === gameMode
                ? 'btn btn-default active'
                : 'btn btn-default'
            "
            style="width: auto"
            @click="setCurrentGameMode(gameMode)"
            >{{ gameMode }}
          </a>
          <a class="btn btn-default next"
            ><i class="fa fa-angle-right fa-fw"></i
          ></a>
          <hr style="border-color: #5fff11" />
        </div>
        <div class="col-xs-2">
          <div class="hs_sidebar">
            <h4>Skills</h4>
            <div class="news_divider"></div>
            <div class="list-group">
              <a
                v-for="skill in skills"
                :key="skill"
                :class="
                  currentSkill === skill
                    ? 'list-group-item active'
                    : 'list-group-item'
                "
                @click="setCurrentSkill(skill)"
                ><img
                  :src="
                    require(`@/assets/hiscores/images/skill_icons/${skill}-icon.png`)
                  "
                />
                {{ skill }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-xs-8" style="padding-left: 0">
          <table
            v-if="!currentUserRouteParam"
            class="table"
            style="margin-top: 0"
          >
            <tbody v-if="!loadingCurrentPage">
              <tr>
                <td style="width: 30px"></td>
                <td style="width: 30px"></td>
                <td style="width: 130px">Username</td>
                <td style="width: 130px" class="text-right">
                  Combat
                </td>
                <td style="width: 130px" class="text-right">
                  Total Level
                </td>
                <td style="width: 130px" class="text-right">
                  Experience
                </td>
                <td style="width: 130px" class="text-right">
                  Completion
                </td>
              </tr>
              <tr
                v-for="player in currentPage"
                :key="player.username"
                @click="currentUserRouteParam = player.username"
              >
                <td style="width: 30px; text-align: center">
                  <img
                    v-if="findImageFromRights(player.rights)"
                    :src="findImageFromRights(player.rights).image"
                    style="vertical-align: middle"
                  />
                </td>
                <td style="width: 30px">
                  {{ player.rank_number }}
                </td>
                <td style="width: 130px">
                  <a>{{ player.username }}</a>
                </td>
                <td style="width: 130px" class="text-right">138</td>
                <td style="width: 130px" class="text-right">
                  {{ player.total_level }}
                </td>
                <td style="width: 130px" class="text-right">
                  {{ formatNumber(player.xp) }}
                </td>
                <td style="width: 160px" class="text-right">
                  <div
                    class="progress progress-striped active"
                    style="margin: 0; text-align: center"
                  >
                    <div class="percent">
                      {{ getCompletedPercentage(player.xp) }}%
                    </div>
                    <div
                      class="progress-bar progress-bar-success"
                      :style="`width: ${getCompletedPercentage(
                        player.xp
                      )}%`"
                    ></div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td style="width: 30px"></td>
                <td style="width: 30px"></td>
                <td style="width: 130px">Username</td>
                <td style="width: 130px" class="text-right">
                  Combat
                </td>
                <td style="width: 130px" class="text-right">
                  Total Level
                </td>
                <td style="width: 130px" class="text-right">
                  Experience
                </td>
                <td style="width: 130px" class="text-right">
                  Completion
                </td>
              </tr>
              <tr
                v-for="player in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                :key="player"
                class="loading-skeleton"
              >
                <td style="width: 30px; text-align: center"></td>
                <td style="width: 30px">
                  <span>12</span>
                </td>
                <td style="width: 130px">
                  <span>Usernameeeeeee</span>
                </td>
                <td style="width: 130px" class="text-right">
                  <span>138138</span>
                </td>
                <td style="width: 130px" class="text-right">
                  <span>2496245</span>
                </td>
                <td style="width: 130px" class="text-right">
                  <span>50,000,000,000</span>
                </td>
                <td style="width: 160px" class="text-right">
                  <div
                    class="progress progress-striped active"
                    style="margin: 0; text-align: center"
                  >
                    <div class="percent" style="text-shadow: none">
                      <span>100%</span>
                    </div>
                    <div
                      class="progress-bar progress-bar-success"
                      :style="`width: 100%`"
                    ></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-else>
            <table v-if="!loadingCurrentUser" class="table">
              <tbody>
                <tr>
                  <td style="width: 50px" class="text-center">
                    Icon
                  </td>
                  <td style="width: 160px" class="text-right">
                    Experience
                  </td>
                  <td style="width: 160px" class="text-right">
                    Level
                  </td>
                  <td style="width: 160px" class="text-right">
                    To Next Level
                  </td>
                  <td style="width: 160px" class="text-right">
                    Until Maxed
                  </td>
                </tr>
                <tr v-for="skill in skills" :key="skill">
                  <td style="width: 50px" class="text-center">
                    <img
                      :src="
                        require(`@/assets/hiscores/images/skill_icons/${skill}-icon.png`)
                      "
                    />
                  </td>
                  <td class="text-right" style="width: 160px">
                    {{ xpForStat(skill) }}
                  </td>
                  <td style="width: 160px" class="text-right">
                    {{
                      skill == 'Overall'
                        ? currentUserStats['total_level']
                        : getLevelForXp(
                            currentUserStats[
                              `${skill.toLowerCase()}_xp`
                            ],
                            skill
                          )
                    }}
                  </td>
                  <td style="width: 160px" class="text-right">
                    {{
                      formatNumber(
                        getNextLevelXp(
                          currentUserStats[
                            `${skill.toLowerCase()}_xp`
                          ],
                          skill
                        )
                      )
                    }}
                  </td>
                  <td style="width: 160px" class="text-right">
                    {{
                      formatNumber(
                        untilMaxed(
                          currentUserStats[
                            `${skill.toLowerCase()}_xp`
                          ],
                          skill
                        )
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <a
            v-if="!currentUserStats"
            class="btn btn-default"
            :disabled="currentPageNumber == 1"
            @click="previousPage"
          >
            <i class="fa fa-chevron-left"></i> Previous Page</a
          >

          <a
            v-if="!currentUserStats"
            class="btn btn-default"
            style="float: right"
            @click="nextPage"
            >Next Page <i class="fa fa-chevron-right"></i
          ></a>
        </div>
        <div class="col-xs-2" style="padding-left: 0">
          <div class="hs_sidebar">
            <h4>Search</h4>
            <div class="news_divider"></div>
            <div class="panel-body">
              <div class="form-group">
                <input
                  v-model="searchInput"
                  type="text"
                  class="form-control"
                  name="user"
                  placeholder="Username"
                  required=""
                />
              </div>
              <div style="text-align: right">
                <button
                  class="btn btn-default btn-block"
                  style="margin: 0"
                  @click="searchForUser"
                >
                  <i class="fa fa-search"></i> Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HiscoresPage',
  data: () => ({
    modes: [
      'Normal',
      'Ironman',
      'Hardcore Ironman',
      'Pk Account',
      'Realism',
    ],
    skills: [
      'Overall',
      'Attack',
      'Defence',
      'Strength',
      'Constitution',
      'Ranged',
      'Prayer',
      'Magic',
      'Cooking',
      'Woodcutting',
      'Fletching',
      'Fishing',
      'Firemaking',
      'Crafting',
      'Smithing',
      'Mining',
      'Herblore',
      'Agility',
      'Thieving',
      'Slayer',
      'Farming',
      'Runecrafting',
      'Hunter',
      'Construction',
      'Summoning',
      'Dungeoneering',
    ],
    currentSkill: 'Overall',
    currentGameMode: 'Normal',
    currentPage: [],
    currentPageNumber: 1,
    rankIcons: [
      {
        rights: 1,
        image: require('@/assets/hiscores/images/ranks/1.png'),
      },
      {
        rights: 2,
        image: require('@/assets/hiscores/images/ranks/2.png'),
      },
      {
        rights: 3,
        image: require('@/assets/hiscores/images/ranks/3.png'),
      },
      {
        rights: 4,
        image: require('@/assets/hiscores/images/ranks/4.png'),
      },
      {
        rights: 5,
        image: require('@/assets/hiscores/images/ranks/5.png'),
      },
      {
        rights: 6,
        image: require('@/assets/hiscores/images/ranks/6.png'),
      },
      {
        rights: 7,
        image: require('@/assets/hiscores/images/ranks/7.png'),
      },
      {
        rights: 8,
        image: require('@/assets/hiscores/images/ranks/8.png'),
      },
      {
        rights: 9,
        image: require('@/assets/hiscores/images/ranks/9.png'),
      },
      {
        rights: 10,
        image: require('@/assets/hiscores/images/ranks/10.png'),
      },
      {
        rights: 11,
        image: require('@/assets/hiscores/images/ranks/11.png'),
      },
      {
        rights: 12,
        image: require('@/assets/hiscores/images/ranks/12.png'),
      },
      {
        rights: 13,
        image: require('@/assets/hiscores/images/ranks/13.png'),
      },
      {
        rights: 14,
        image: require('@/assets/hiscores/images/ranks/14.png'),
      },
      {
        rights: 15,
        image: require('@/assets/hiscores/images/ranks/15.png'),
      },
      {
        rights: 16,
        image: require('@/assets/hiscores/images/ranks/16.png'),
      },
      {
        rights: 17,
        image: require('@/assets/hiscores/images/ranks/17.png'),
      },
      {
        rights: 18,
        image: require('@/assets/hiscores/images/ranks/18.png'),
      },
      {
        rights: 19,
        image: require('@/assets/hiscores/images/ranks/19.png'),
      },
      {
        rights: 21,
        image: require('@/assets/hiscores/images/ranks/21.gif'),
      },
      {
        rights: 22,
        image: require('@/assets/hiscores/images/ranks/22.png'),
      },
    ],
    currentUserStats: undefined,
    loadingCurrentUser: true,
    currentUserRouteParam: undefined,
    searchInput: undefined,
    loadingCurrentPage: true,
  }),
  watch: {
    currentPageNumber() {
      this.fetchCurrentPage();
    },
    currentSkill() {
      this.fetchCurrentPage();
    },
    currentGameMode() {
      this.fetchCurrentPage();
    },
    currentUserRouteParam() {
      if (!this.currentUserRouteParam) return;

      this.loadingCurrentUser = true;

      this.fetchUsersHiscores().then((response) => {
        if (response.data.length === 0) {
          this.currentUserRouteParam = undefined;
          return;
        }
        this.currentUserStats = response.data[0];
        this.loadingCurrentUser = false;
      });
    },
  },
  mounted() {
    this.fetchHiscoresPage().then((response) => {
      this.currentPage = response.data;
      this.loadingCurrentPage = false;
    });
  },
  methods: {
    getLevelForXp(exp, skill) {
      let points = 0;
      let output = 0;

      for (
        let lvl = 1;
        lvl <= (skill === 'Dungeoneering' ? 120 : 99);
        lvl++
      ) {
        points += Math.floor(lvl + 300.0 * Math.pow(2.0, lvl / 7.0));
        output = Math.floor(points / 4);
        if (output - 1 >= exp) {
          return lvl;
        }
      }

      return skill === 'Dungeoneering' ? 120 : 99;
    },
    getXpForLevel(level) {
      let points = 0;
      let output = 0;

      for (let lvl = 1; lvl <= level; lvl++) {
        points += Math.floor(lvl + 300.0 * Math.pow(2.0, lvl / 7.0));
        if (lvl >= level) {
          return output;
        }
        output = Math.floor(points / 4);
      }

      return 0;
    },
    fetchCurrentPage() {
      this.loadingCurrentPage = true;

      this.fetchHiscoresPage().then((response) => {
        this.currentPage = [];
        this.currentPage = response.data;
        this.currentUserRouteParam = undefined;
        this.currentUserStats = undefined;
        this.loadingCurrentPage = false;
      });
    },
    setCurrentSkill(skill) {
      this.currentSkill = skill;
    },
    setCurrentGameMode(gameMode) {
      this.currentGameMode = gameMode;
    },
    fetchHiscoresPage() {
      return axios.get(
        `${
          process.env.VUE_APP_MAIN_API_URL
        }/api/hiscores/get_users/s-${
          this.currentSkill
        }/m-${this.currentGameMode
          .toLowerCase()
          .replaceAll(' ', '_')}/${this.currentPageNumber - 1}`
      );
    },
    fetchUsersHiscores() {
      return axios.get(
        `${process.env.VUE_APP_MAIN_API_URL}/api/hiscores/user/${this.currentUserRouteParam}`
      );
    },
    nextPage() {
      this.currentPageNumber += 1;
    },
    previousPage() {
      if (this.currentPageNumber != 1) {
        this.currentPageNumber -= 1;
      }
    },
    findImageFromRights(rights) {
      return this.rankIcons.find(
        (rankIcon) => rankIcon.rights === rights
      );
    },
    formatNumber(number) {
      return new Intl.NumberFormat('en-US').format(number);
    },
    getCompletedPercentage(totalXp) {
      const totalSkillXp = 2000000000;
      const totalAccountXp = 50000000000;

      if (this.currentSkill === 'Overall') {
        return ((totalXp / totalAccountXp) * 100).toFixed(0);
      }

      return ((totalXp / totalSkillXp) * 100).toFixed(0);
    },
    xpForStat(skill) {
      return this.formatNumber(
        this.currentUserStats[`${skill.toLowerCase()}_xp`]
      );
    },
    getNextLevelXp(currentXp, skill) {
      if (skill === 'Overall') return '';

      const currentLevel = this.getLevelForXp(currentXp);

      if (currentLevel === 99) return 0;

      const nextLevelXp = this.getXpForLevel(currentLevel + 1);

      return nextLevelXp - currentXp;
    },
    untilMaxed(currentXp, skill) {
      const totalSkillXp = 2000000000;
      const totalAccountXp = 50000000000;

      if (skill === 'Overall') {
        return totalAccountXp - currentXp;
      }

      return totalSkillXp - currentXp;
    },
    openOverall() {
      this.currentSkill = 'Overall';
      this.fetchCurrentPage();
    },
    searchForUser() {
      this.currentUserRouteParam = this.searchInput;
    },
  },
};
</script>

<style lang="scss" scoped>
@import url(/hiscores/assets/css/bootstrap.min.css);
@import url(/hiscores/assets/css/font-awesome.css);

body {
  background-image: url(/images/header.jpg),
    url(/images/body-bg-min.jpg);
  background-position: top 90px center, 0px 0px;
  background-size: contain, auto;
  background-repeat: no-repeat, repeat;
  background-attachment: scroll;
  background-color: #262824;
  font-size: 14px;
}
.navigation {
  position: relative;
}
.button.large.play {
  top: 110px;
}
.text-info {
  color: #a9fd12;
}

.body-wrapper {
  background-color: #11271d;
  padding: 0;
  padding-top: 5px;
  margin-bottom: 70px;
  max-width: 1200px;
  box-shadow: 0 0 5px #000;
  display: table;
  margin-right: auto;
  margin-left: auto;
  background-position: 50% 0%;
  background-size: auto;
  background-repeat: repeat-y;
  border: 1px solid #3e5239;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);
  color: #b8b8b8;
}

.nav-tabs {
  border-bottom: 1px solid #111414;
  padding: 0;
}
.nav-tabs > li {
  padding: 0;
  float: right;
  border-radius: 0;
}
.nav-tabs > li > a {
  background: rgba(32, 36, 36, 0.71);
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #151919;
  border-radius: 0;
  color: #777;
}
.nav-tabs > li > a:hover {
  background: rgba(255, 255, 255, 0.03);
  color: #777;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background: #16171a;
  color: rgba(255, 255, 255, 0.75);
  border: 1px solid #111;
  border-bottom: 1px solid #151919;
  margin: 0;
}
.nav-tabs > li.active > a {
  border-bottom: none;
}
.tab-content {
  border-left: solid 1px #111414;
  border-right: solid 1px #111414;
  border-bottom: solid 1px #111414;
  background-color: #151919;
}
.hero-box {
  padding-top: 25px;
  padding-bottom: 75px;
  border-top: solid 1px #444;
  margin-top: -15px;
}
.hero-box h2 {
  text-shadow: 1px 1px 2px #000;
  text-transform: uppercase;
}
.hero-box p {
  font-size: 16px;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  z-index: 2;
  border-color: #5fff11;
}
hr.custom-sep {
  width: 200px;
  border-style: groove;
  border-width: 2px;
  border-color: #333;
  border-left: none;
  border-right: none;
  border-top: none;
}
.carousel-indicators li {
  background-color: #111;
  border: solid 1px #111;
}
.carousel-indicators li.active {
  background-color: #888;
  border: solid 1px #888;
}
.feature-box {
  padding-top: 90px;
  padding-bottom: 50px;
  background-color: rgba(255, 255, 255, 0.02);
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 0px solid #222;
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.02);
}
.page-btns {
  padding: 10px;
  padding-left: 0;
  color: white;
  padding-right: 0;
}
.skill-title {
  font-size: 24px;
  vertical-align: baseline;
}
.skill-title img {
  vertical-align: baseline;
  max-height: 18px;
}
.btn-default {
  background-color: #131313;
  font-size: 14px;
  text-align: center;
  color: #e7e7e7;
  border-radius: 3px;
  border: solid 1px rgba(255, 255, 255, 0.05);
  box-shadow: none;
  border-top: solid 1px #0d0e0c;
  border-bottom: solid 1px #0d0e0c;
  transition: all 0.3s ease-in-out;
  margin: 3px;
}
.btn-default:hover,
.btn-default:focus {
  background-color: #26513e;
  text-align: center;
  color: #aafd10;
  border-radius: 3px;
  border: solid 1px rgb(107 172 42);
  transition: all 0.3s ease-in-out;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-color: #26513e;
  text-align: center;
  color: #aafd10;
  border-radius: 3px;
  border: solid 1px rgb(107 172 42);
  transition: all 0.3s ease-in-out;
}
.btn-default .active {
  background-color: #26513e;
  text-align: center;
  color: #aafd10;
  border-radius: 3px;
  border: solid 1px rgb(107 172 42);
  transition: all 0.3s ease-in-out;
}
.percent {
  position: absolute;
  width: 144px;
  color: #ddd;
  text-shadow: 1px 1px 1px #000;
}
.progress {
  background-color: #252924;
  border-radius: 0;
  border: solid 1px #2f352c;
  box-shadow: inset 0 0 3px #111;
}
.hs-header {
  margin-top: 10px;
  margin-bottom: 20px;
  height: auto;
  padding: 15px;
  color: white;
  text-shadow: 0 0 10px #524b39;
}
.hs-header h1 {
  font-family: 'Titillium Web', sans-serif;
  font-size: 52px;
  line-height: 52px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  margin: 0;
  text-transform: uppercase;
  text-shadow: 1px 0 15px #000;
}

.hs-header h4 {
  text-align: center;
  margin-top: 0;
  font-size: 20px;
}
.hs-header h4 a {
  color: #aaa;
}
.hs-header h4 a:hover {
  color: #fff;
  text-decoration: none;
}
.hs-header p {
  font-size: 14px;
  font-weight: 300;
  color: #aaa;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  text-align: center;
}
a.list-group-item {
  color: #aaa;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #0d0e0c;
  transition: all 0.2s ease-in-out;
}
a.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
a.list-group-item:hover,
a.list-group-item:focus {
  color: #5fff11;
  text-decoration: none;
  background-color: #0a4a2f;
}
a.list-group-item img {
  transition: all 0.2s ease-in-out;
  float: right;
}
a.list-group-item:hover img {
  transform: translateX(-20px);
  -webkit-transform: translateX(-20px);
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
input.form-control {
  padding: 5px;
  font-size: 13px;
  border-radius: 0px;
  outline-offset: 0px;
  color: rgba(255, 255, 255, 0.6);
  outline: none;
  margin-right: 15px;
  box-shadow: inset 0px 1px 4px #111;
  border: 1px solid #26513e;
  background: #232722;
}
.form-control:focus {
  border-color: #6aaa29;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(60 72 48);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgb(60 72 48);
}
.panel,
.panel-primary {
  background-color: #212121;
  border-color: #151515;
}
.panel-primary > .panel-heading {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}
.panel-primary > .panel-footer {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}
.skill-box {
  background: #0e3323;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3),
    0 1px 3px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(255, 255, 255, 0.03) inset;
  margin: 5px;
  transition: all 200ms ease 0s;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
}

.table > tbody > tr {
  background: #0e3323;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3),
    0 1px 3px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(255, 255, 255, 0.03) inset;
  margin: 0 0 1px;
  display: flex;
  transition: all 200ms ease 0s;
  border-top: 1px solid #0d0e0c;
}

.table > tbody > tr:first-child {
  background: #26513e;
  border-bottom: 1px solid #5fff11;
  letter-spacing: 0px;
  font-size: 13px;
  text-transform: uppercase;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.89);
  border-top: 0px;
}
.table > tbody > tr:first-child:hover {
  background: #26513e;
}

.table > tbody > tr:hover {
  background: #0a4a2f;
}
.hs_sidebar {
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3),
    0 1px 3px rgba(0, 0, 0, 0.5),
    0 0 0 1px rgba(255, 255, 255, 0.03) inset;
  background: #0e3323;
  margin-bottom: 15px;
}
.news_divider {
  margin: 0 10px 5px 0;
  height: 2px;
}
.hs_sidebar h4 {
  margin-left: 0px;
  padding-top: 0px;
  margin-top: 0;
  margin-bottom: 0px;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;
  background: #26513e;
  border-bottom: 1px solid #5fff11;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.89);
}

.progress-bar-success {
  background-color: #89e01c;
}

.text-success {
  color: #00aa00;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: rgba(0, 200, 0, 0.1);
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: rgba(255, 0, 0, 0.1);
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: rgba(255, 200, 0, 0.1);
}
.alert-danger {
  background-color: rgba(255, 0, 0, 0.1);
  border-color: #aa0000;
  color: #ff0000;
}
.footer_copyright {
  padding: 15px 10px;
  width: 1040px;
  background: #1d2121;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  clear: both;
  border-radius: 0 0 3px 3px;
  margin: auto;
  margin-bottom: 20px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0) inset,
    0 -1px 0 rgba(255, 255, 255, 0.1) inset, 0 1px 0 #0b0b0a,
    0 -1px 0 #0b0b0a, 0 2px 0 rgba(0, 130, 250, 1),
    0 -2px 0 rgba(118, 101, 63, 0), 0 0 8px rgba(0, 0, 0, 0),
    0 2px 2px rgba(0, 0, 0, 1), 0 -2px 2px rgba(0, 0, 0, 0);
}
.footer_copyright p {
  margin: 0;
}
.info {
  display: inline-block;
  height: 30px;
  margin-top: 0;
  line-height: 50px;
}
.prev {
  margin-right: 15px;
}
.next {
  margin-left: 15px;
}

@media screen and (max-width: 991px) {
  body {
    background-size: auto, auto;
  }
  .hs-header {
    margin-top: 20px;
  }
}

%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #2a2e27;
  border-color: #2a2e27;
  text-shadow: transparent;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  span,
  .text-loading,
  .form-control {
    @extend %loading-skeleton;
  }
}
</style>
