<template>
  <div class="product-container-block">
    <div class="store-item-name">{{ product.productName }}</div>
    <div class="product-price-row-flex">
      <div class="product-price">${{ product.productPrice }}</div>
    </div>
    <div class="product-image-container">
      <a
        class="product-information-icon w-inline-block"
        @click="openModal(product)"
        ><i class="fa fa-info fa-fw" style="margin-top: 5px"></i></a
      ><img
        :src="imageUrl(product)"
        loading="lazy"
        alt=""
        class="product-image"
      />
    </div>
    <a
      class="button w-button addtocart"
      @click="$emit('add-product-to-cart', product)"
      >ADD TO CART</a
    >

    <div
      v-if="showInfoModal && selectedProduct"
      class="modal"
      @click="showInfoModal = false"
    >
      <div class="content-box modal-content">
        <div class="maintitle-section-block">
          <div class="medium-title">
            {{ selectedProduct.productName }}
          </div>
        </div>
        <div class="modal-item-information-grid">
          <img
            :src="imageUrl(selectedProduct)"
            loading="lazy"
            alt=""
          />
          <p v-html="selectedProduct.productDescription"></p>
        </div>
        <a
          id="close-popup"
          class="close-popup-icon w-inline-block"
          style="cursor: pointer"
          @click="showInfoModal = false"
          ><i class="fa fa-close fa-fw" style="margin-left: 3px"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductBlock',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showInfoModal: false,
    selectedProduct: undefined,
  }),
  methods: {
    imageUrl(product) {
      return product.productImage.includes('http')
        ? product.productImage
        : `https://store.simplicityps.org/${product.productImage}`;
    },
    openModal(product) {
      this.selectedProduct = product;
      this.showInfoModal = true;
    },
    doNothing() {
      return;
    },
  },
};
</script>

<style scoped>
@import url(/hiscores/assets/css/font-awesome.css);

.product-information-icon {
  cursor: pointer;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(20, 63, 44, 0.5);
}

/* Modal Content/Box */
.modal-content {
  background-color: #11271d;
  margin: 15% auto; /* 15% from the top and centered */
  max-width: 600px; /* Could be more or less, depending on screen size */
  text-align: left;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
