<template>
  <div class="page-section cta">
    <div class="container">
      <div class="cta-content">
        <h1 class="page-heading cta">
          DOWNLOAD &amp; PLAY<br /><span class="page-heading large"
            >FOR FREE TODAY!</span
          >
        </h1>
        <a href="/play" class="header-play-btn w-inline-block">
          <div>play now</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallToAction',
};
</script>
