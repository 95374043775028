<template>
  <div>
    <div class="header-section sub-page">
      <div class="header-text">
        <h1 class="page-heading">
          SIMPLICITY<br /><span class="page-heading large"
            >STORE</span
          >
        </h1>
      </div>
    </div>
    <div class="page-section">
      <div class="container">
        <div v-if="status">
          <div
            v-if="status == 'success'"
            class="maintitle-section-block alert-text"
            style="padding: 20px; margin: 10px 0 20px 0"
          >
            <i class="fa fa-check fa-fw"></i> Thank you! Your purchase
            was successful. Please type <code>::claim</code> ingame to
            claim your purchase.
          </div>
          <div
            v-if="status == 'cancel'"
            class="maintitle-section-block-error alert-text"
            style="padding: 20px; margin: 10px 0 20px 0"
          >
            <i class="fa fa-exclamation fa-fw"></i> There was a
            problem processing your payment. Please try again or
            contact support if problems persist.
          </div>
        </div>
        <div v-if="paymentStatus">
          <div
            v-if="paymentStatus == 'COMPLETED'"
            class="maintitle-section-block alert-text"
            style="padding: 20px; margin: 10px 0 20px 0"
          >
            <i class="fa fa-check fa-fw"></i> Thank you! Your purchase
            was successful. Please type <code>::claim</code> ingame to
            claim your purchase.
          </div>
          <div
            v-else
            class="maintitle-section-block-error alert-text"
            style="padding: 20px; margin: 10px 0 20px 0"
          >
            <i class="fa fa-exclamation fa-fw"></i> There was a
            problem processing your payment. Please try again or
            contact support if problems persist.
          </div>
        </div>
        <div class="store-layout-grid">
          <div>
            <div v-if="!loading.categories" class="content-box">
              <div class="store-category-tabs-grid">
                <a
                  v-for="category in categories"
                  :key="category.categoryName"
                  :class="`category-tab-btn w-inline-block store-buttons ${
                    selectedCategory.id === category.id
                      ? 'active'
                      : ''
                  }`"
                  @click="selectedCategory = category"
                >
                  <img
                    :src="categoryImageUrl(category)"
                    loading="lazy"
                    class="category-tab-icon"
                  />
                  <div class="">
                    {{ category.categoryName }}
                  </div>
                </a>
              </div>
            </div>
            <div v-else class="content-box">
              <div class="store-category-tabs-grid">
                <a
                  v-for="index in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                  :key="index"
                  class="category-tab-btn w-inline-block store-buttons loading-skeleton"
                >
                  <div
                    style="
                      width: 30px;
                      height: 30px;
                      -webkit-border-radius: 25px;
                      -moz-border-radius: 25px;
                      border-radius: 25px;
                      background: #295742;
                      margin-right: 5px;
                    "
                  ></div>
                  <div class="text-loading">Loading...</div>
                </a>
              </div>
            </div>

            <div v-if="loading.products">
              <div class="content-box tablecat loading-skeleton">
                <div class="maintitle-section-block">
                  <div class="medium-title">
                    <div class="text-loading">Loading...</div>
                  </div>
                </div>

                <div class="store-products-grid">
                  <div
                    v-for="product in [1, 2, 3]"
                    :key="product"
                    class="product-container-block"
                    style="background-image: none"
                  >
                    <div class="store-item-name">
                      <span class="text-loading">
                        Loading Product...
                      </span>
                    </div>
                    <div class="product-price-row-flex">
                      <div style="margin-top: 4px">
                        <span class="text-loading"> $500.00 </span>
                      </div>
                    </div>
                    <div class="product-image-container">
                      <div
                        class="product-image"
                        style="
                          width: 150px;
                          height: 150px;
                          background: #295742;
                        "
                      ></div>
                    </div>
                    <a class="button w-button addtocart"
                      ><div class="text-loading">ADD TO CART</div></a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                v-for="category in categories"
                :key="category.id"
                class="content-box tablecat"
                :style="`display: ${
                  category.id === selectedCategory.id
                    ? 'block'
                    : 'none'
                }`"
              >
                <div class="maintitle-section-block">
                  <div class="medium-title">
                    {{ category.categoryName }}
                  </div>
                </div>

                <div class="store-products-grid">
                  <product-block
                    v-for="product in productsForCategoryId(
                      category.id
                    )"
                    :key="product.id"
                    :product="product"
                    @add-product-to-cart="addProductToCart"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="store-fixed-panel">
            <daily-deals @add-product-to-cart="addProductToCart" />
            <cart @open-checkout="showCheckoutModal = true" />
          </div>
        </div>
      </div>
    </div>
    <checkout-form
      :show="showCheckoutModal"
      @payment-completed="paymentCompleted"
      @close="showCheckoutModal = false"
    />
  </div>
</template>

<script>
import axios from 'axios';
import ProductBlock from '@/components/Store/ProductBlock';
import DailyDeals from '@/components/Store/DailyDeals';
import Cart from '@/components/Store/Cart';
import CheckoutForm from '@/components/Store/CheckoutForm.vue';

export default {
  name: 'StorePage',
  components: { ProductBlock, DailyDeals, Cart, CheckoutForm },
  data: () => ({
    loading: {
      categories: true,
      products: true,
    },
    categories: [],
    products: [],
    selectedCategory: undefined,
    showCheckoutModal: false,
    paymentStatus: undefined,
  }),
  computed: {
    status() {
      return this.$route.query.status;
    },
  },
  watch: {
    status() {
      if (this.status === 'success') {
        this.clearCart();
      }
    },
  },
  beforeCreate() {
    this.$store.commit('store/initialiseCartItems');
  },
  mounted() {
    if (this.loading.categories) this.getAllCategories();
  },
  methods: {
    getAllCategories() {
      axios
        .get(`${process.env.VUE_APP_STORE_API_URL}/get_categories`)
        .then((response) => {
          this.categories = response.data;
          this.loading.categories = false;

          this.selectedCategory = response.data[0];
          this.getAllProducts();
        });
    },
    getAllProducts() {
      axios
        .get(`${process.env.VUE_APP_STORE_API_URL}/get_products`)
        .then((response) => {
          this.products = response.data;
          this.loading.products = false;
        });
    },
    productsForCategoryId(categoryId) {
      return this.products.filter(
        (product) => product.productCategoryId === categoryId
      );
    },
    categoryImageUrl(category) {
      return category.image.includes('http')
        ? category.image
        : `https://store.simplicityps.org/${category.image}`;
    },
    addProductToCart(product) {
      this.$store.dispatch('store/addCartItem', product);
    },
    clearCart() {
      this.$store.dispatch('store/clearCart');
    },
    paymentCompleted(status) {
      this.showCheckoutModal = false;
      this.paymentStatus = status;
      this.clearCart();
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  border-color: #5fff11;
  background-color: #26513e;
  box-shadow: 0 0 20px -9px #5fff11;
}

.category-tab-btn {
  cursor: pointer;
}

.alert-text {
  font-family: Kanit, sans-serif;
  color: #fff;
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  text-transform: uppercase;
}

.maintitle-section-block-error {
  border-bottom: 1px solid #ff0000;
  background-color: #3a0707;
}

%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #295742;
  border-color: #295742;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  .text-loading,
  .form-control {
    @extend %loading-skeleton;
  }
}
</style>
