<template>
  <div v-if="show" class="modal">
    <div class="content-box modal-content">
      <div class="maintitle-section-block">
        <div class="medium-title">Checkout</div>
      </div>
      <div class="store-checkout-container">
        <div class="cart-form w-form">
          <div class="store-cart-container">
            <ul role="list" class="cartlist">
              <li
                v-for="item in cartItems"
                :key="item.id"
                class="cart-item-list"
              >
                <div>x{{ item.quantity }} {{ item.productName }}</div>
                <div class="cart-item-right-div">
                  <div class="cart-item-price">
                    ${{
                      item.isPromoted
                        ? item.tempDiscountPrice * item.quantity
                        : item.productPrice * item.quantity
                    }}
                  </div>
                  <a
                    class="delete-icon w-inline-block"
                    @click="removeProduct(item.id)"
                    ><i class="fa fa-trash fa-fw"></i
                  ></a>
                </div>
              </li>
            </ul>
          </div>
          <ul role="list">
            <li class="cart-item-list pricing">
              <div>Total:</div>
              <div class="cart-item-right-div">
                <div class="cart-item-price totalprice">
                  ${{ totalPrice }}
                </div>
              </div>
            </li>
            <li class="cart-item-list pricing">
              <div>Your IP Address:</div>
              <div class="cart-item-right-div">
                <div class="cart-item-price">{{ userIp }}</div>
              </div>
            </li>
          </ul>

          <div
            class="w-col-6"
            style="display: inline-block; padding-right: 5px"
          >
            <label>Enter your Simplicity Username</label>
            <input
              v-model="username"
              type="text"
              class="input-field w-input"
              placeholder="Enter in-game username"
              required
            />
          </div>
          <div
            class="w-col-6"
            style="display: inline-block; padding-left: 5px"
          >
            <label for="Email-address">Email Address</label>
            <input
              v-model="emailAddress"
              type="email"
              class="input-field w-input"
              placeholder="Enter your email address"
              required
            />
          </div>

          <label class="w-checkbox">
            <div
              :class="`w-checkbox-input w-checkbox-input--inputType-custom checkbox ${
                acceptedTermsAndConditions
                  ? 'w--redirected-checked'
                  : ''
              }`"
            ></div>
            <input
              v-model="acceptedTermsAndConditions"
              type="checkbox"
              required
              style="opacity: 0; position: absolute; z-index: -1"
            /><span class="w-form-label" style="margin-top: 7px"
              >I accept the
              <a href="#">Terms &amp; Conditions</a></span
            >
          </label>

          <div class="w-row">
            <div
              class="w-col-6"
              style="display: inline-block; padding-right: 5px"
            >
              <div
                id="paypal-button-container"
                :style="`display: ${
                  !acceptedTermsAndConditions ? 'none' : 'block'
                }`"
              ></div>
              <input
                :style="`display: ${
                  !acceptedTermsAndConditions ? 'block' : 'none'
                }`"
                type="submit"
                value="Pay with PayPal"
                class="button checkout paypal w-button"
                style="width: 100%"
                disabled
              />
            </div>
            <div
              class="w-col-6"
              style="
                display: inline-block;
                padding-left: 5px;
                vertical-align: top;
              "
            >
              <input
                type="submit"
                :value="
                  checkingOut
                    ? 'Redirecting to checkout...'
                    : 'Checkout with Card'
                "
                data-wait=""
                class="button checkout paypal w-button"
                name="checkout"
                style="width: 100%"
                :disabled="!acceptedTermsAndConditions"
                @click="stripeCheckout"
              />
            </div>
          </div>

          <div
            class="checkout-claim-text"
            style="text-align: center; color: #fff"
          >
            Once you have completed your payment, type
            <code>::claim</code> on the server to receive purchases.
          </div>
        </div>
        <a
          id="close-popup"
          class="close-popup-icon w-inline-block"
          style="cursor: pointer"
          @click="$emit('close')"
          ><i class="fa fa-close fa-fw" style="margin-left: 3px"></i
        ></a>
      </div>
    </div>
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  name: 'CheckoutForm',
  components: { StripeCheckout },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    userIp: 'Loading...',
    username: undefined,
    emailAddress: undefined,
    acceptedTermsAndConditions: false,
    loading: false,
    publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
    sessionId: undefined,
    checkingOut: false,
  }),
  computed: {
    ...mapGetters('store', ['cartItems']),
    totalPrice() {
      return this.cartItems.reduce(
        (sum, product) =>
          sum +
          (product.isPromoted
            ? product.tempDiscountPrice * product.quantity
            : product.productPrice * product.quantity),
        0
      );
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.$loadScript(
          `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}`
        ).then(() => {
          // eslint-disable-next-line no-undef
          paypal
            .Buttons({
              // eslint-disable-next-line no-undef
              fundingSource: paypal.FUNDING.PAYPAL,
              style: {
                layout: 'horizontal',
                color: 'gold',
                shape: 'rect',
                label: 'pay',
              },
              createOrder: (data, actions) => {
                // This function sets up the details of the transaction, including the amount and line item details.
                return actions.order.create({
                  purchase_units: this.cartItems.map((cartItem) =>
                    this.paypalItem(
                      (
                        cartItem.quantity * cartItem.productPrice
                      ).toString(),
                      cartItem.id
                    )
                  ),
                });
              },
              onApprove: (data, actions) => {
                return actions.order.capture().then((details) => {
                  this.$emit('payment-completed', details.status);
                });
              },
            })
            .render('#paypal-button-container');
        });
      }
    },
  },
  mounted() {
    this.fetchUserIpAddress();
  },
  methods: {
    removeProduct(productId) {
      this.$store.dispatch('store/removeCartItem', productId);
    },
    paypalItem(total, productId) {
      return {
        reference_id: productId,
        amount: {
          currency_code: 'USD',
          value: total,
        },
      };
    },
    fetchUserIpAddress() {
      axios.get('https://ipapi.co/json/').then((response) => {
        this.userIp = response.data.ip;
      });
    },
    stripeCheckout() {
      this.checkingOut = true;

      let keys = this.cartItems.map((cartItem) => cartItem.id);
      let values = this.cartItems.map((cartItem) =>
        cartItem.quantity.toString()
      );
      let formattedItems = {};

      for (let i = 0; i < keys.length; i++) {
        formattedItems[keys[i]] = values[i];
      }

      axios
        .post(
          `${process.env.VUE_APP_STORE_API_URL}/create_session/stripe`,
          {
            username: this.username,
            email: this.emailAddress,
            items: formattedItems,
          }
        )
        .then((response) => {
          this.sessionId = response.data.session;
          this.$refs.checkoutRef.redirectToCheckout();

          this.checkingOut = false;
        });
    },
  },
};
</script>

<style scoped>
@import url(/hiscores/assets/css/font-awesome.css);

.product-information-icon {
  cursor: pointer;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(20, 63, 44, 0.5);
}

/* Modal Content/Box */
.modal-content {
  background-color: #11271d;
  margin: 10% auto; /* 15% from the top and centered */
  max-width: 600px; /* Could be more or less, depending on screen size */
  text-align: left;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.w-button:disabled {
  color: #ffffff82;
  border-color: #646464;
  background-color: #3232326b;
  cursor: not-allowed;
}

.w-button:disabled:hover {
  color: #ffffff82;
  border-color: #646464;
  background-color: #3232326b;
}

.button {
  padding: 7px 20px;
}
</style>
