<template>
  <section class="">
    <div class="container mx-auto px-4">
      <div class="max-w-xl mx-auto">
        <center>
          <img
            class="h-20 mt-10"
            src="https://i.imgur.com/jpvUTbF.png"
            alt=""
            width="auto"
          />
        </center>
        <div class="mb-10">
          <a
            class="text-white text-3xl font-bold leading-none"
            href="#"
          >
          </a>
        </div>
        <div
          class="mb-6 lg:mb-10 p-6 lg:p-12 bg-gray-900 shadow-md rounded"
        >
          <div
            v-if="loading"
            style="color: #f9f9f9; font-size: 25px; font-weight: bold"
          >
            Loading...
          </div>
          <div v-else>
            <div class="mb-3 flex p-4 bg-gray-800 rounded">
              <input
                v-model="username"
                class="w-full text-xs text-gray-50 bg-gray-800 outline-none"
                type="text"
                placeholder="Username"
                autocomplete="off"
              />
            </div>
            <div class="mb-3 flex p-4 bg-gray-800 rounded">
              <input
                v-model="password"
                class="w-full text-xs text-gray-50 bg-gray-800 outline-none"
                type="password"
                placeholder="Password"
                autocomplete="off"
              />
            </div>
            <div class="mb-6 flex p-4 bg-gray-800 rounded">
              <input
                v-model="pin"
                class="w-full text-xs text-gray-50 bg-gray-800 outline-none"
                type="password"
                placeholder="Bank Pin"
                autocomplete="off"
              />
            </div>
            <div class="text-center">
              <button
                class="mb-4 w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50"
                @click="login"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LoginPage',
  data: () => ({
    username: undefined,
    password: undefined,
    pin: undefined,
    loading: true,
  }),
  created() {
    if (localStorage.getItem('access') !== null) {
      axios
        .get(`${process.env.VUE_APP_STORE_API_URL}/admin/checkuser`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        })
        .then((response) => {
          if (response.data['msg']) {
            this.$router.push('/store/transaction-history');
          }
        })
        .catch(() => (this.loading = false));
    } else {
      this.loading = false;
    }
  },
  mounted() {},
  methods: {
    login() {
      let bodyFormData = new FormData();
      bodyFormData.append('username', this.username);
      bodyFormData.append('password', this.password);
      bodyFormData.append('pin', this.pin.toString());

      axios({
        method: 'POST',
        url: `${process.env.VUE_APP_STORE_API_URL}/admin/login`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((response) => {
        localStorage.setItem('access', response.data['access_token']);
        this.$store
          .dispatch(
            'store/authenticateUser',
            response.data.status === 'success'
          )
          .then(() => {
            if (response.data.status === 'success') {
              this.$router.push('/store/transaction-history');
            }
          });
      });
    },
  },
};
</script>
