<template>
  <div class="content-box" style="padding-bottom: 20px">
    <div class="maintitle-section-block">
      <div class="medium-title">{{ currentMonth }}'s Top Voters</div>
    </div>
    <ul
      v-if="loadingVoters"
      role="list"
      class="daily-deals-list loading-skeleton-ul"
    >
      <li class="store-deal-list-item">
        <div
          class="deal-list-item-grid loading-skeleton-voters"
        ></div>
      </li>
      <li class="store-deal-list-item">
        <div
          class="deal-list-item-grid loading-skeleton-voters"
        ></div>
      </li>
      <li class="store-deal-list-item">
        <div
          class="deal-list-item-grid loading-skeleton-voters"
        ></div>
      </li>
    </ul>

    <ul v-else role="list" class="daily-deals-list">
      <li
        v-for="(user, index) in topVoters"
        :key="user.username"
        class="store-deal-list-item"
      >
        <div class="deal-list-item-grid">
          <img
            :src="
              require('@/assets/hiscores/images/skill_icons/' +
                (index == 0
                  ? 'gold.png'
                  : index == 1
                  ? 'silver.png'
                  : 'bronze.png'))
            "
            loading="lazy"
            alt=""
            class="deals-item-img"
          />
          <div class="store-item-name">{{ user.username }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'TopVoters',
  data: () => ({
    topVoters: [],
    loadingVoters: true,
  }),
  computed: {
    currentMonth() {
      return moment().format('MMMM');
    },
  },
  created() {
    this.getTopVoters();
  },
  methods: {
    async getTopVoters() {
      await axios
        .get(`${process.env.VUE_APP_MAIN_API_URL}/api/votes/topvoter`)
        .then((response) => {
          if (response.data.length > 0) {
            this.topVoters = response.data;
          }
        })
        .finally(() => (this.loadingVoters = false))
        .catch(() => (this.loadingVoters = true));
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-skeleton-ul {
  pointer-events: none;
  animation: loading-skeleton-data-v-c1259796 1s infinite alternate;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: loading-skeleton-data-v-c1259796;
}
.loading-skeleton-voters {
  min-height: 42px;
}
</style>
