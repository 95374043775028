import Vue from 'vue';
import App from '@/App.vue';
import axios from 'axios';
import router from '@/router';
import store from '@/store';
import VueCountdownTimer from 'vuejs-countdown-timer';
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
Vue.use(VueCountdownTimer);

Vue.config.productionTip = false;

new Vue({
  router,
  axios,
  store,
  render: (h) => h(App),
}).$mount('#app');
