<template>
  <div id="cart" class="content-box cart">
    <div class="maintitle-section-block">
      <div class="medium-title">your cart</div>
    </div>
    <div class="cart-form w-form">
      <div class="store-cart-container">
        <ul role="list" class="cartlist">
          <li v-if="cartItems.length == 0" class="cartisempty">
            Your cart is empty
          </li>
          <li
            v-for="item in cartItems"
            v-else
            :key="item.id"
            class="cart-item-list cart-item-<?php echo $row['id']; ?>"
          >
            <div>x{{ item.quantity }} {{ item.productName }}</div>
            <div class="cart-item-right-div">
              <div class="cart-item-price">
                ${{
                  item.isPromoted
                    ? item.tempDiscountPrice * item.quantity
                    : item.productPrice * item.quantity
                }}
              </div>
              <a
                class="delete-icon w-inline-block"
                @click="removeProduct(item.id)"
                ><i class="fa fa-trash fa-fw"></i
              ></a>
            </div>
          </li>
        </ul>
      </div>
      <div class="discord-code-row">
        <input
          id="cpncode"
          type="text"
          class="input-field no-margin w-input cpncode"
          maxlength="256"
          name="cpncode"
          data-name="cpncode"
          placeholder="Enter Discount Code"
        />
        <a href="#" class="button faded w-button cpnapply">Apply</a>
      </div>
      <ul role="list">
        <li class="cart-item-list pricing">
          <div>Total:</div>
          <div class="cart-item-right-div">
            <div class="cart-item-price totalprice">
              ${{ totalPrice }}
            </div>
          </div>
        </li>
      </ul>
      <div class="cart-buttons-flex">
        <input
          value="GO TO CHECKOUT"
          type="submit"
          class="button checkout w-button checkout-button"
          :disabled="!cartHasItems"
          @click="$emit('open-checkout')"
        />
        <a
          id="emptycart"
          class="button faded w-button"
          @click="clearCart"
          >Empty</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Cart',
  computed: {
    ...mapGetters('store', ['cartItems']),
    totalPrice() {
      return this.cartItems.reduce(
        (sum, product) =>
          sum +
          (product.isPromoted
            ? product.tempDiscountPrice * product.quantity
            : product.productPrice * product.quantity),
        0
      );
    },
    cartHasItems() {
      return this.cartItems.length > 0;
    },
  },
  methods: {
    removeProduct(productId) {
      this.$store.dispatch('store/removeCartItem', productId);
    },
    clearCart() {
      this.$store.dispatch('store/clearCart');
    },
  },
};
</script>

<style scoped>
@import url(/hiscores/assets/css/font-awesome.css);

.checkout-button:disabled {
  color: #bbb;
  cursor: not-allowed;
  border-color: #5fff113b;
  background-color: #2a70513b;
}

.checkout-button:disabled:hover {
  color: #bbb;
  cursor: not-allowed;
  border-color: #5fff113b;
  background-color: #2a70513b;
}
</style>
