<template>
  <div>
    <div class="header-section">
      <div
        v-if="promoCode"
        style="
          background-color: #00000075;
          font-family: 'Montserrat', sans-serif;
          text-transform: uppercase;
          margin-bottom: 5%;
          padding: 1rem;
        "
      >
        <div style="text-align: center">
          <b
            ><span style="color: #4fbe13"
              >TYPE
              <span style="text-transform: uppercase; color: #00ff00"
                >::PROMOCODE {{ promoCode }}</span
              >
              IN-GAME TO GET YOUR PRIZE!</span
            ></b
          >
          <br /><b
            ><span style="color: #4fbe13"
              >Giving this code out to anyone is against the
              rules.</span
            ></b
          >
        </div>
      </div>

      <div class="header-text">
        <h1 class="page-heading">
          ENDLESS HOURS OF<br /><span class="page-heading large"
            >GAME-PLAY FOR FREE</span
          >
        </h1>
      </div>
      <router-link to="/play" class="header-play-btn w-inline-block">
        <div>play now</div>
      </router-link>
    </div>
    <div class="header-buttons-section">
      <div class="container narrow">
        <div class="header-btn-3-col-grid">
          <a
            href="https://discord.com/invite/NjbEDNg"
            target="_blank"
            class="header-btn-template w-inline-block"
            ><img
              :src="
                require('@/assets/images/Header-Option-discord.jpg')
              "
              loading="lazy"
              alt=""
              class="header-btn-image"
            />
            <div class="header-btn-content">
              <div class="medium-title">Join Discord</div>
              <p>Engage with the community!</p>
            </div>
          </a>
          <a
            href="https://community.simplicityps.org"
            target="_blank"
            class="header-btn-template w-inline-block"
            ><img
              :src="
                require('@/assets/images/Header-Option-community.jpg')
              "
              loading="lazy"
              alt=""
              class="header-btn-image"
            />
            <div class="header-btn-content">
              <div class="medium-title">Go to forums</div>
              <p>Guides, News and more!</p>
            </div>
          </a>
          <a
            href="/play"
            target="_blank"
            class="header-btn-template w-inline-block"
            ><img
              :src="
                require('@/assets/images/Header-Option-window.jpg')
              "
              loading="lazy"
              alt=""
              class="header-btn-image"
            />
            <div class="header-btn-content">
              <div class="medium-title">Download</div>
              <p>Start playing for free today!</p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="container">
        <div class="about-grid">
          <div class="about-introduction">
            <div class="maintitle-sub-label">INTRODUCTION</div>
            <h3 class="large-title">Who are we?</h3>
            <p class="large-paragraph">
              Simplicity is a free-to-play multiplayer online
              role-playing game (MMORPG) owned by a team of
              professional developers who have a deep passion for
              gaming. The game offers a comprehensive experience with
              its Skilling system, Player vs Monster (PvM), Player vs
              Player (PvP), Bosses, Quests, Achievements, Mini-games,
              Seasonal Battle Pass, and much more!
              <br /><br />
              Challenge fearsome bosses for rewarding drops, complete
              collection logs to earn massive rewards, and unlock
              exclusive ranks. Immerse yourself in a world filled with
              adventures. With a thriving community and thousands of
              players, your journey to the top of the SimplicityPS
              hiscores awaits. Become the best and join the ranks of
              the elite! Download the game client and start your
              adventure today!
              <router-link to="/play">
                Download the game client
              </router-link>
              and play now for free!
            </p>
          </div>
          <div>
            <img
              :src="require('@/assets/images/Introduction-Image.jpg')"
              loading="lazy"
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 60vw"
              alt=""
              class="introduction-image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page-section textured">
      <div class="container">
        <div class="game-2-col-grid">
          <div class="content-box">
            <div class="maintitle-section-block">
              <div class="medium-title">in-game activity</div>
            </div>
            <ul role="list" class="game-activity-list">
              <li class="game-activity-list-item">
                <div>
                  <span class="green-text"
                    >Temporarily unavailable</span
                  >
                </div>
              </li>
            </ul>
          </div>
          <div v-if="rssFeed" class="forum-news-grid">
            <div class="maintitle-section-block no-margin">
              <div class="medium-title">latest news</div>
            </div>
            <a
              v-for="feedItem in rssFeed"
              :key="feedItem.title"
              :href="feedItem.link"
              class="content-box news w-inline-block"
            >
              <div class="news-information">
                <div class="news-title">
                  {{ feedItem.title }}
                </div>
                <div>Posted: {{ feedItem.pubDate }}</div>
              </div>
              <div class="button">
                <div>READ MORE</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="container">
        <div
          data-duration-in="300"
          data-duration-out="100"
          data-easing="ease-in-out"
          class="game-content-section-grid"
        >
          <div class="game-content-selection">
            <a
              :class="tabImageClasses(0)"
              @click="changeCurrentTab(0)"
              ><img
                :src="
                  require('@/assets/images/chambers-of-xeric.jpg')
                "
                loading="lazy"
                alt="Chambers of Xeric"
                class="game-tab-image-bg"
            /></a>
            <a
              :class="tabImageClasses(1)"
              @click="changeCurrentTab(1)"
              ><img
                :src="require('@/assets/images/Theatre-of-blood.jpg')"
                loading="lazy"
                alt="Theatre of Blood"
                class="game-tab-image-bg"
            /></a>
            <a
              :class="tabImageClasses(2)"
              @click="changeCurrentTab(2)"
              ><img
                :src="require('@/assets/images/nightmare-boss.jpg')"
                loading="lazy"
                alt="Nightmare Boss"
                class="game-tab-image-bg"
            /></a>
            <a
              :class="tabImageClasses(3)"
              @click="changeCurrentTab(3)"
              ><img
                :src="
                  require('@/assets/images/300-active-players.jpg')
                "
                loading="lazy"
                alt="300 Active Players"
                class="game-tab-image-bg"
            /></a>
            <a
              :class="tabImageClasses(4)"
              @click="changeCurrentTab(4)"
              ><img
                :src="require('@/assets/images/Active-community.jpg')"
                loading="lazy"
                alt="Active Community"
                class="game-tab-image-bg"
            /></a>
          </div>
          <div class="game-tab-content">
            <div :class="tabPaneClasses(0)">
              <div class="maintitle-sub-label">featuring</div>
              <h3 class="large-title">Chambers of Xeric</h3>
            </div>
            <div :class="tabPaneClasses(1)">
              <div class="maintitle-sub-label">featuring</div>
              <h3 class="large-title">Theatre of Blood</h3>
            </div>
            <div :class="tabPaneClasses(2)">
              <div class="maintitle-sub-label">featuring</div>
              <h3 class="large-title">Nightmare Boss</h3>
            </div>
            <div :class="tabPaneClasses(3)">
              <div class="maintitle-sub-label">featuring</div>
              <h3 class="large-title">300+ Active Players</h3>
            </div>
            <div :class="tabPaneClasses(4)">
              <div class="maintitle-sub-label">featuring</div>
              <h3 class="large-title">Active Community</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="container">
        <div class="page-section-text">
          <div class="maintitle-sub-label">
            WHAT GAME-PLAY DO WE HAVE?
          </div>
          <h3 class="large-title">GAME FEATURES</h3>
        </div>
        <div class="game-features-grid">
          <div class="content-box light">
            <div class="game-feature-block-flex">
              <img
                :src="
                  require('@/assets/images/game-features-pvm-content.png')
                "
                loading="lazy"
                alt="PvM Content-Nex-2011"
                class="game-feature-icon"
              />
              <div>
                <div class="small-title">PVM CONTENT</div>
                <p>
                  Explore the various bosses and their drops that are
                  spread around Simplicity. Get a very unique preview
                  of their rare drops when you teleport to their
                  location.
                </p>
              </div>
            </div>
          </div>
          <div class="content-box light">
            <div class="game-feature-block-flex">
              <img
                :src="require('@/assets/images/F-keys.png')"
                loading="lazy"
                alt="F-keys"
                class="game-feature-icon"
              />
              <div>
                <div class="small-title">F-KEYS</div>
                <p>
                  F-Keys are useful shortcut keys assigned to perform
                  specific tasks quickly in place of mouse clicks.
                  Especially in player vs player combat.
                </p>
              </div>
            </div>
          </div>
          <div class="content-box light">
            <div class="game-feature-block-flex">
              <img
                :src="
                  require('@/assets/images/game-features-presets.png')
                "
                loading="lazy"
                alt="Gear Presets"
                class="game-feature-icon"
              />
              <div>
                <div class="small-title">gear PRESETS</div>
                <p>
                  Bank &amp; Equipment presets are available ingame to
                  speed up the process of switching between content
                  and restocking for a trip.<br />
                </p>
              </div>
            </div>
          </div>
          <div class="content-box light">
            <div class="game-feature-block-flex">
              <img
                :src="
                  require('@/assets/images/game-features-marketplace.png')
                "
                loading="lazy"
                alt="Marketplace"
                class="game-feature-icon"
              />
              <div>
                <div class="small-title">marketplace</div>
                <p>
                  Ever wanted a stable Market? Use the player owned
                  shops to easily trade. You can search for items or
                  usernames and purchase and sell items.<br />
                </p>
              </div>
            </div>
          </div>
          <div class="content-box light">
            <div class="game-feature-block-flex">
              <img
                :src="
                  require('@/assets/images/game-features-bosses.png')
                "
                loading="lazy"
                alt="07 Bosses"
                class="game-feature-icon"
              />
              <div>
                <div class="small-title">bosses</div>
                <p>
                  Explore all the other 07 content and features
                  Simplicity provides! We aim at a server mixed with
                  up to date content along with pre-Eoc content.
                </p>
              </div>
            </div>
          </div>
          <div class="content-box light">
            <div class="game-feature-block-flex">
              <img
                :src="
                  require('@/assets/images/game-features-events.png')
                "
                loading="lazy"
                alt="Community Events"
                class="game-feature-icon"
              />
              <div>
                <div class="small-title">EVENTS</div>
                <p>
                  Join the community through our forums or on Discord
                  to participate in server-wide events and group
                  activities, hosted by players and the staff team.<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section discord">
      <div class="container narrow">
        <div class="discord-section-grid">
          <div>
            <div class="maintitle-sub-label discord">our discord</div>
            <h3 class="large-title">
              Join the<br />community discord
            </h3>
            <a
              href="https://discord.com/invite/NjbEDNg"
              target="_blank"
              class="button large discord-btn w-button"
              >Connect to Discord</a
            >
          </div>
          <div class="discord-embed w-embed w-iframe">
            <iframe
              src="https://discord.com/widget?id=479651506493063171&theme=dark"
              width="100%"
              height="100%"
              allowtransparency="true"
              frameborder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="page-section">
      <div class="container narrow">
        <div class="video-section-grid">
          <div
            style="padding-top: 56.17021276595745%"
            class="w-embed-youtubevideo youtube-video-embed"
          >
            <iframe
              src="https://www.youtube-nocookie.com/embed/v83TN0hxqvo?rel=1&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
              frameborder="0"
              style="
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                pointer-events: auto;
              "
              allow="autoplay; encrypted-media"
              allowfullscreen=""
            ></iframe>
          </div>
          <div>
            <div class="maintitle-sub-label">community video</div>
            <h3 class="large-title">
              New Player<br />Beginner Guide
            </h3>
            <a
              href="https://www.youtube.com/watch?v=6J88nkVMVW8"
              target="_blank"
              class="button large w-button"
              >WATCH GUIDE</a
            >
          </div>
        </div>
      </div>
    </div>

    <call-to-action />
  </div>
</template>

<script>
import axios from 'axios';
import CallToAction from '@/components/Common/CallToAction';

export default {
  name: 'HomePage',
  components: { CallToAction },
  data: () => ({
    currentTab: 0,
    rssFeed: undefined,
  }),
  computed: {
    promoCode() {
      const referrer = this.$route.query.ref;

      if (!referrer) return null;

      let code = null;

      switch (referrer.toLowerCase()) {
        case 'sohan':
          code = 'sohan';
          break;
        case 'mopar5':
          code = 'mopar5';
          break;
        case '50box':
          code = 'WINTER';
          break;
        case 'rl':
        case 'runelocus':
          code = 'WINTER';
          break;
        default:
          code = 'WINTER';
      }
      if (referrer.toLowerCase().includes('50box')) {
        code = 'WINTER';
      }

      return code;
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_MAIN_API_URL}/api/forums/rss`)
      .then((response) => (this.rssFeed = response.data));
  },
  methods: {
    changeCurrentTab(tabNumber) {
      this.currentTab = tabNumber;
    },
    tabPaneClasses(tabNumber) {
      return `w-tab-pane ${
        tabNumber === this.currentTab ? 'w--tab-active' : ''
      }`;
    },
    tabImageClasses(tabNumber) {
      return `game-content-tab-image w-inline-block w-tab-link ${
        tabNumber === this.currentTab ? 'w--current' : ''
      }`;
    },
  },
};
</script>
