const mutations = {
  setCartItems: (state, cartItems) => {
    state.cartItems = cartItems;
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  },
  initialiseCartItems: (state) => {
    if (localStorage.getItem('cartItems')) {
      state.cartItems = JSON.parse(localStorage.getItem('cartItems'));
    }
  },
  setAdminAuthenticated: (state, adminAuthenticated) => {
    state.adminAuthenticated = adminAuthenticated;
  },
};

export default mutations;
